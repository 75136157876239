import { trpc } from "../Root.tsx";
import AptLine from "./AptLine.tsx";
import { ExistingRecuringAppointment } from "dependaclock-server/src/types/types.ts";

interface AptListProps {
  onEdit: (aptId: string) => void;
}
const AptList = (props: AptListProps) => {
  const { data: appointments } = trpc.appointment.getAll.useQuery();
  const recuringAppointments = appointments?.filter(
    (apt) => apt.type === "recuring"
  ) as ExistingRecuringAppointment[];
  const oneTimeAppointments = appointments?.filter(
    (apt) => apt.type === "oneTime"
  );

  const utils = trpc.useContext();
  const deleteApointmentMutation = trpc.appointment.delete.useMutation({
    onSettled: () => {
      utils.appointment.invalidate();
    },
  });
  const togglePauseMutation = trpc.appointment.togglePause.useMutation({
    onSettled: () => {
      utils.appointment.invalidate();
    },
  });
  const handleDelete = (_id: string) => {
    const result = confirm("Voulez-vous vraiment supprimer ce rdv ?");
    if (result) {
      deleteApointmentMutation.mutate({ _id });
    }
  };
  const handleTogglePause = (_id: string) => {
    togglePauseMutation.mutate({ _id });
  };
  return (
    <div>
      <div className="border-2 border-slate-200 rounded-md mt-4 shadow bg-white">
        <div className="mx-2 border-b-2 border-b-slate-500 font-sans text-2xl font-medium">
          Rdv récurrents
        </div>
        <div className="">
          {recuringAppointments?.map((apt) => (
            <AptLine
              key={apt._id}
              apt={apt}
              onDelete={() => handleDelete(apt._id)}
              onTogglePause={() => handleTogglePause(apt._id)}
              onEdit={() => props.onEdit(apt._id)}
            />
          ))}
          {recuringAppointments?.length === 0 && (
            <div className="text-center text-2xl mt-4 mb-4">
              Aucun rdv récurrent
            </div>
          )}
        </div>
      </div>
      <div className="border-2 border-slate-200 rounded-md mt-4 shadow bg-white">
        <div className="mx-2 border-b-2 border-b-slate-500 font-sans text-2xl font-medium">
          Rdv uniques
        </div>

        <div className="mb-4">
          {oneTimeAppointments?.map((apt) => (
            <AptLine
              apt={apt}
              key={apt._id}
              onDelete={() => handleDelete(apt._id)}
              onTogglePause={() => {}}
              onEdit={() => props.onEdit(apt._id)}
            />
          ))}
          {oneTimeAppointments?.length === 0 && (
            <div className="text-center text-2xl mt-4">Aucun rdv unique</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AptList;
