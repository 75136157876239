import React from "react";
import { DateTime } from "luxon";
import { trpc } from "../Root.tsx";
import {
  ExistingAppointment,
  ExistingAppointmentSchema,
  NewAppointmentSchema,
} from "dependaclock-server/src/types/types.ts";

const jours = [
  "dimanche",
  "lundi",
  "mardi",
  "mercredi",
  "jeudi",
  "vendredi",
  "samedi",
] as const;

interface AptEditProps {
  apt: ExistingAppointment | null;
  onCancel: () => void;
}

const AptEdit = (props: AptEditProps) => {
  const [isRecuring, setIsRecuring] = React.useState(
    props.apt?.type === "recuring" || false
  );
  const [day, setDay] = React.useState<(typeof jours)[number]>(
    props.apt?.type === "recuring" ? props.apt.day : "lundi"
  );
  const [date, setDate] = React.useState<Date | null>(
    props.apt?.type === "oneTime" ? props.apt.date : null
  );
  const [hour, setHour] = React.useState<number | null>(
    props.apt?.hour != null ? props.apt.hour : null
  );
  const [minute, setMinute] = React.useState<number | null>(
    props.apt?.minute != null ? props.apt.minute : 0
  );
  const [title, setTitle] = React.useState<string>(props.apt?.title || "");

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const isValid =
    title && hour != null && minute != null && (isRecuring ? !!day : !!date);

  const utils = trpc.useContext();

  const handleToggleRecuring = () => {
    setIsRecuring((isRecuring) => !isRecuring);
  };
  const commitAptMutation = trpc.appointment.create.useMutation({
    onSettled: () => {
      setIsSubmitting(false);
      props.onCancel();
      utils.appointment.invalidate();
    },
  });
  const saveEditedAptMutation = trpc.appointment.saveEdited.useMutation({
    onSettled: () => {
      setIsSubmitting(false);
      props.onCancel();
      utils.appointment.invalidate();
    },
  });
  const handleCommit = () => {
    setIsSubmitting(true);
    if (!props.apt) {
      const validated = NewAppointmentSchema.parse({
        title,
        type: isRecuring ? "recuring" : "oneTime",
        day,
        date,
        hour,
        minute,
        paused: false,
      });
      commitAptMutation.mutate(validated);
    } else {
      const validated = ExistingAppointmentSchema.parse({
        ...props.apt,
        title,
        type: isRecuring ? "recuring" : "oneTime",
        day,
        date,
        hour,
        minute,
      });
      saveEditedAptMutation.mutate(validated);
    }
  };
  const handleCancel = () => {
    props.onCancel();
  };
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className="border-2 border-slate-200 rounded-md mt-4 shadow bg-white p-4">
        <div className="flex ml-2">
          <label htmlFor="title">Description</label>
          <input
            className="border-slate-600 border-solid border-2 rounded w-full ml-2"
            type="text"
            id="description"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
        </div>
        <div className="mt-2">
          <input
            type="checkbox"
            checked={isRecuring}
            onChange={handleToggleRecuring}
            id="isRecuringCheck"
            className="border-slate-600 border-solid border-2 ml-2"
          />
          <label htmlFor="isRecuringCheck" className="ml-2">
            Récurrent
          </label>
        </div>
        <div className="m-2 flex">
          <label htmlFor="hour" className="mr-2">
            Heure
          </label>
          <input
            type="number"
            id="hour"
            value={hour || 0}
            className="border-slate-600 border-solid border-2 rounded w-16"
            onChange={(event) => setHour(parseInt(event.target.value))}
          />
          <label htmlFor="minute" className="mx-2">
            Minute
          </label>
          <input
            type="number"
            id="minute"
            value={minute || 0}
            className="border-slate-600 border-solid border-2 rounded w-16"
            onChange={(event) => setMinute(parseInt(event.target.value))}
          />
        </div>
        {isRecuring ? (
          <div className="m-3">
            <label htmlFor="daySelect">Jour</label>
            <select
              id={"daySelect"}
              value={day}
              className="ml-2"
              onChange={(event) =>
                setDay(event.target.value as (typeof jours)[number])
              }
            >
              {jours.map((jour) => (
                <option key={jour} value={jour}>
                  {jour}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="m-3">
            <label htmlFor="date" className="mr-2">
              Date
            </label>
            <input
              type="date"
              id="date"
              value={date ? DateTime.fromJSDate(date).toISODate() || "" : ""}
              className="border-slate-600  border-solid border-2 rounded"
              onChange={(e) =>
                setDate(DateTime.fromISO(e.target.value).toJSDate())
              }
            />
          </div>
        )}
        <div className="border-t p-2 m-2 flex justify-center">
          <button
            className="bg-slate-700 rounded p-2 text-white disabled:opacity-50"
            disabled={!isValid || isSubmitting}
            onClick={handleCommit}
          >
            Enregistrer
          </button>
          <button
            className="bg-slate-400 rounded p-2 ml-2 text-white disabled:opacity-50"
            onClick={handleCancel}
          >
            Abandonner
          </button>
        </div>
      </div>
    </form>
  );
};

export default AptEdit;
