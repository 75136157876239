import { DateTime } from "luxon";
import { formaters } from "vincent-utils";
import SunIcon from "./SunIcon.tsx";
import MoonIcon from "./MoonIcon.tsx";

interface MomentOfDayProps {
  hour: number;
}

const MomentOfDay = (props: MomentOfDayProps) => {
  const { hour } = props;

  const momentOfDay =
    hour < 7
      ? "la nuit"
      : hour < 12
      ? "matin"
      : hour < 18
      ? "après-midi"
      : hour < 24
      ? "soir"
      : "la nuit";
  const today = DateTime.now().setLocale("fr");
  const dayString = formaters.capitaliseFirstLetter(today.toFormat("EEEE"));
  const previousDayString = formaters.capitaliseFirstLetter(
    today.minus({ days: 1 }).toFormat("EEEE")
  );
  if (momentOfDay === "la nuit") {
    return (
      <>
        <div className="flex justify-center">
          <MoonIcon />
        </div>
        <div className="text-9xl text-blue-700 mx-auto text-center">
          La nuit
        </div>
        <div className="text-5xl mx-auto text-center ">
          entre {previousDayString} et {dayString}
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="flex justify-center">
          {momentOfDay === "matin" || momentOfDay === "après-midi" ? (
            <>
              <SunIcon />
            </>
          ) : (
            ""
          )}
          {momentOfDay === "soir" ? (
            <>
              <MoonIcon />
            </>
          ) : (
            ""
          )}
        </div>
        <div className="text-8xl mx-auto my-8 text-center">
          {dayString} {momentOfDay}
        </div>
      </>
    );
  }
};

export default MomentOfDay;
