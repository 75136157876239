import { useState } from "react";
import poster from "../utils/poster.ts";

import { z } from "zod";

//TODO : à améliorer
const prefix = "dependaclockRest";

interface LoginScreenProps {
  callBack: () => void;
}
const LoginScreen = (props: LoginScreenProps) => {
  // const [email, setEmail] = useState("");
  // const [username, setUsername] = useState("");
  const [emailOrUsername, setEmailOrUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasErrors, _setHasError] = useState(false);
  const onSubmit = () => {
    if (
      z.string().email().safeParse(emailOrUsername).success &&
      z.string().min(6).safeParse(password).success
    ) {
      poster(`${prefix}/loginUser`, { email: emailOrUsername, password }).then(
        (response) => {
          if (response) {
            props.callBack();
          }
        }
      );
      return;
    }
    if (
      z.string().min(4).safeParse(emailOrUsername).success &&
      z.string().min(6).safeParse(password).success
    ) {
      poster(`${prefix}/loginUser`, {
        username: emailOrUsername,
        password,
      }).then((response) => {
        if (response) {
          props.callBack();
        }
      });
      return;
    }

    // Meteor.loginWithPassword(email, password, (error) => {
    //   if (error) {
    //     setHasError(true);
    //   }
    // });
  };
  // const isLoggingIn = useTracker(() => Meteor.loggingIn());
  const isLoggingIn = false;
  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-center">
        <div className="w-1/2">
          <div className="font-sans text-2xl content-center flex flex-col">
            <div className="mx-auto">Login DEPENDACLOCK</div>
            <div className="mx-auto">Espace Aidants</div>
          </div>
          <div className="flex flex-col content-center">
            {hasErrors ? (
              <div className="alert alert-danger" role="alert">
                Mauvaise combinaison email/mot de passe
              </div>
            ) : null}
            <input
              type="email"
              className="mx-auto border-solid border-2 border-black rounded-md mt-4"
              id="emailInput"
              value={emailOrUsername}
              onChange={(e) => setEmailOrUsername(e.target.value)}
              placeholder="Nom d'utilisateur"
            />
            <input
              type="password"
              className="mx-auto border-solid border-2 border-black rounded-md mt-4"
              id="passwordInput"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSubmit();
                }
              }}
              placeholder="Mot de passe"
            />
            <button
              type="button"
              className="mx-auto border-solid border-2 border-black rounded-md mt-4"
              disabled={!emailOrUsername || !password || isLoggingIn}
              onClick={onSubmit}
            >
              Entrer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
