import React from "react";
import AptList from "./AptList.tsx";
import AptEdit from "./AptEdit.tsx";
import { trpc } from "../Root.tsx";

const AidantMainPanel = () => {
  const [isAddingApt, setIsAddingApt] = React.useState(false);
  const [selectedAptId, setSelectedAptId] = React.useState<string | null>(null);
  const { data: apt, isFetched } = trpc.appointment.getById.useQuery(
    { _id: selectedAptId as string },
    { enabled: !!selectedAptId }
  );
  const handleEdit = (aptId: string) => {
    setSelectedAptId(aptId);
    setIsAddingApt(true);
  };
  const handleCancel = () => {
    setIsAddingApt(false);
    setSelectedAptId(null);
  };
  return (
    <div className="flex">
      <div className="mx-auto w-3/4 md:w-1/2 xl:w-1/3">
        <AptList onEdit={handleEdit} />
        <div className="flex justify-center mt-2">
          <button
            className="bg-slate-700 rounded p-2 text-white"
            onClick={() => setIsAddingApt(true)}
          >
            Ajouter un RDV
          </button>
        </div>
        {isAddingApt && (selectedAptId ? isFetched : true) ? (
          <AptEdit apt={apt || null} onCancel={handleCancel} />
        ) : null}
      </div>
    </div>
  );
};

export default AidantMainPanel;
