import { z } from "zod";

const jours = [
  "lundi",
  "mardi",
  "mercredi",
  "jeudi",
  "vendredi",
  "samedi",
  "dimanche",
] as const;
export const NewRecuringAppointmentSchema = z.object({
  title: z.string(),
  day: z.enum(jours),
  hour: z.number(),
  minute: z.number(),
  type: z.literal("recuring"),
  paused: z.literal(false),
});

export const ExistingRecuringAppointmentSchema =
  NewRecuringAppointmentSchema.extend({
    _id: z.string(),
    userId: z.string(),
    paused: z.boolean(),
  });

export type ExistingRecuringAppointment = z.infer<
  typeof ExistingRecuringAppointmentSchema
>;

export const NewOneTimeAppointmentSchema = z.object({
  title: z.string(),
  date: z.date(),
  hour: z.number(),
  minute: z.number(),
  type: z.literal("oneTime"),
});

export const ExistingOneTimeAppointmentSchema =
  NewOneTimeAppointmentSchema.extend({
    _id: z.string(),
    userId: z.string(),
  });
export type ExistingOneTimeAppointment = z.infer<
  typeof ExistingOneTimeAppointmentSchema
>;

export const NewAppointmentSchema = z.union([
  NewRecuringAppointmentSchema,
  NewOneTimeAppointmentSchema,
]);

export type NewAppointment = z.infer<typeof NewAppointmentSchema>;

export const ExistingAppointmentSchema = z.union([
  ExistingRecuringAppointmentSchema,
  ExistingOneTimeAppointmentSchema,
]);

export type ExistingAppointment = z.infer<typeof ExistingAppointmentSchema>;

export const UserSchema = z.object({
  _id: z.string(),
  username: z.string(),
  messagename: z.string().optional(),
  emails: z
    .array(
      z.object({
        address: z.string().email(),
        verified: z.boolean(),
      })
    )
    .optional(),
  smsname: z.string().optional(),
  services: z.object({
    password: z.object({
      bcrypt: z.string(),
    }),
  }),
  active: z.boolean(),
});

export type User = z.infer<typeof UserSchema>;

export type Migration = {
  _id: string;
  name: string;
};
