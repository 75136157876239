import React, { useEffect } from "react";
import { formaters } from "vincent-utils";
import TodayAppointments from "./TodayAppointments.tsx";
import CatIcon from "./CatIcon.tsx";

const RightPanel = () => {
  const [today, setToday] = React.useState(
    formaters.filterDateTimeForPureDate(new Date()).getTime()
  );
  useEffect(() => {
    const update = () => {
      setToday(formaters.filterDateTimeForPureDate(new Date()).getTime());
    };
    update();
    const interval = setInterval(() => {
      update();
    }, 60000);
    return () => clearInterval(interval);
  }, []);
  const [hour, setHour] = React.useState(0);
  const [minute, setMinute] = React.useState(0);
  useEffect(() => {
    const update = () => {
      const date = new Date();
      let _hour = date.getHours();
      const _minute = date.getMinutes();
      setHour(_hour);
      setMinute(_minute);
    };
    update();
    const interval = setInterval(() => {
      update();
    }, 60000);
    return () => clearInterval(interval);
  }, []);
  return (
    <>
      {hour >= 7 && hour <= 23 ? (
        <TodayAppointments today={today} hour={hour} minute={minute} />
      ) : null}
      {hour >= 0 && hour < 7 ? (
        <div className="w-full">
          <CatIcon />
        </div>
      ) : null}
    </>
  );
};

export default RightPanel;
