import { formaters } from "vincent-utils";
import { DateTime } from "luxon";

interface RecuringApt {
  _id: string;
  title: string;
  day: string;
  hour: number;
  minute: number;
  type: "recuring";
  paused: boolean;
}

interface OneTimeApt {
  _id: string;
  title: string;
  date: Date;
  hour: number;
  minute: number;
  type: "oneTime";
}

type IconBarProps = ShowPauseIconProps | HidePauseIconProps;

interface ShowPauseIconProps {
  onClickDelete: () => void;
  onClickPause: () => void;
  onEdit: () => void;
  showPause: true;
}

interface HidePauseIconProps {
  onClickDelete: () => void;
  onEdit: () => void;
}

const IconBar = (props: IconBarProps) => (
  <>
    {"showPause" in props ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        className="h-10 w-10 cursor-pointer hover:text-blue-600"
        viewBox="2 2 13 13"
        onClick={props.onClickPause}
      >
        <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z" />
      </svg>
    ) : (
      ""
    )}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className="h-10 w-10 cursor-pointer hover:text-blue-600"
      onClick={props.onEdit}
      viewBox="-3 -3 24 24"
    >
      <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
    </svg>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      className="h-10 w-10 cursor-pointer hover:text-blue-600"
      viewBox="-2 -2 21 21"
      onClick={props.onClickDelete}
    >
      <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
    </svg>
  </>
);

interface AptLineProps {
  apt: OneTimeApt | RecuringApt;
  onDelete: () => void;
  onTogglePause: () => void;
  onEdit: () => void;
}

const AptLine = (props: AptLineProps) => {
  const { apt } = props;
  if (apt.type === "recuring") {
    return (
      <div
        key={apt._id}
        className="grid grid-cols-3 border-b border-b-slate-500 mx-2"
      >
        <div className="flex items-center">
          <IconBar
            onClickDelete={props.onDelete}
            showPause
            onClickPause={props.onTogglePause}
            onEdit={props.onEdit}
          />
        </div>
        <div className="flex items-center">
          <div
            className={
              "text-xl ml-2" +
              (apt.paused ? " line-through" : "") +
              " col-span-2 "
            }
          >
            {apt.title} {apt.day} {apt.hour}:
            {formaters.convertSingleDigitToTwoDigits(apt.minute)}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        key={apt._id}
        className="grid grid-cols-3 border-b border-b-slate-500 mx-2"
      >
        <div className="flex items-center">
          <IconBar onClickDelete={props.onDelete} onEdit={props.onEdit} />
        </div>
        <div className="flex items-center">
          <div className="text-xl ml-2 col-span-2">
            {apt.title}{" "}
            {DateTime.fromJSDate(apt.date)
              .setLocale("fr")
              .toFormat("EEEE dd MMMM")}{" "}
            {apt.hour}:{formaters.convertSingleDigitToTwoDigits(apt.minute)}
          </div>
        </div>
      </div>
    );
  }
};

export default AptLine;
