import ClockPanel from "./ClockPanel.tsx";
import RightPanel from "./RightPanel.tsx";

const MainClockAndAptPanel = () => {
  return (
    <div className="flex">
      <div className="flex flex-1 flex-col justify-items-stretch border-r-4 border-r-black bg-sky-50 h-screen">
        <ClockPanel />
      </div>
      <div className="flex flex-1 h-screen">
        <RightPanel />
      </div>
    </div>
  );
};

export default MainClockAndAptPanel;
