import LoginScreen from "./LoginScreen";
import { utils } from "vincent-utils";
import { PropsWithChildren, useEffect, useState } from "react";
import { User } from "dependaclock-server/src/types/types.ts";

const devHost = import.meta.env.VITE_DEV_HOST || "";
const prefix = "dependaclockRest";
const cleanedUrl = "getConnectedUser";
const getUser = async () => {
  const response = await fetch(
    `${devHost ? devHost + "/" : ""}${prefix}/${cleanedUrl}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
  if (response.ok) {
    const text = await response.text();
    return JSON.parse(text, utils.jsonDateReviver);
  } else {
    throw new Error(`invalide json dans fetch ${prefix}/${cleanedUrl}`);
  }
};

const LoginSwitcher = (props: PropsWithChildren) => {
  const [user, setUser] = useState<User | null>(null);
  const [shouldFetch, setShouldFetch] = useState(true);
  useEffect(() => {
    if (!shouldFetch) {
      return;
    }
    setShouldFetch(false);
    getUser().then(setUser);
  }, [shouldFetch]);
  const loginCallBack = () => {
    setShouldFetch(true);
  };
  if (user) {
    return props.children;
  } else {
    return <LoginScreen callBack={loginCallBack} />;
  }
};

export default LoginSwitcher;
