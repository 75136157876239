import { trpc } from "../Root.tsx";
import { formaters } from "vincent-utils";
import { DateTime } from "luxon";

interface TodayAppointmentsProps {
  today: number; //date en number
  hour: number;
  minute: number;
}

const TodayAppointments = (props: TodayAppointmentsProps) => {
  const { today, hour, minute } = props;
  const { data: todayAppointments } = trpc.appointment.getToday.useQuery(
    {
      date: new Date(today),
    },
    {
      refetchInterval: 5 * 60 * 1000,
    }
  );
  const withoutPaused =
    todayAppointments?.filter((apt) => !("paused" in apt) || !apt.paused) || [];
  const withoutPassed = withoutPaused.filter((apt) => {
    return apt.hour * 60 + apt.minute + 60 >= hour * 60 + minute; //on ne garde que les rdvs qui ne sont pas encore passés (avec une marge de 1h)
  });

  const dayString = formaters.capitaliseFirstLetter(
    DateTime.fromJSDate(new Date(today)).setLocale("fr").toFormat("EEEE")
  );
  return (
    <div>
      <div className="border-b-4 border-b-slate-500 mb-2 mt-10 pb-2 font-sans text-4xl font-medium mx-4">
        {hour >= 0 && hour < 6
          ? "Rdvs de demain " + dayString
          : "Rdvs d'aujourd'hui " + dayString}
      </div>
      <div className="text-4xl ml-12 mt-10">
        <ul className="list-disc">
          {withoutPassed.map((apt) => (
            <li key={apt._id}>
              {apt.hour}h{formaters.convertSingleDigitToTwoDigits(apt.minute)}{" "}
              {apt.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TodayAppointments;
