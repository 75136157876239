import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "../index.css";
import Root from "./Root.tsx";
import React from "react";

const container = document.getElementById("renderRoot");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  </React.StrictMode>
);
