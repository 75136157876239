import React, { useEffect } from "react";
import { formaters } from "vincent-utils";
import MomentOfDay from "./MomentOfDay.tsx";

const ClockPanel = () => {
  const [hour, setHour] = React.useState(0);
  const [minute, setMinute] = React.useState(0);
  useEffect(() => {
    const update = () => {
      const date = new Date();
      let hour = date.getHours();
      setHour(hour);
      setMinute(date.getMinutes());
    };

    update();

    const interval = setInterval(() => {
      update();
    }, 60000);

    return () => clearInterval(interval);
  }, []);
  return (
    <>
      <div className="text-10xl text-center">
        {hour}:{formaters.convertSingleDigitToTwoDigits(minute)}
      </div>
      <MomentOfDay hour={hour} />
    </>
  );
};

export default ClockPanel;
